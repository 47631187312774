import React from 'react';
import {Container, Row, Col} from 'react-bootstrap';

import Layout from '../../components/layout';
import SEO from '../../components/seo';
import Section from '../../components/section';
import SectionHero from '../../components/section-hero';
import CardAppPlatform from '../../components/card-app-platform';
import CardAppVizComm from '../../components/card-app-vizcomm';
import NetadLogo from '../../images/netad-logo.png';
import Figure1 from '../../images/netAD-Figure1.png';
import Figure2 from '../../images/netAD-Figure2.png';
import NetadAim1 from '../../images/netad-aim1.png';
import NetadAim2 from '../../images/netad-aim2.png';
import NetadAim3 from '../../images/netad-aim3.png';

const NetadStudyPage = () => (
  <Layout className="study">
    <SEO title="NetAD" />
    <SectionHero className="study__hero" fileName="netad-study-header.png">
      <Container className="study__hero__container">
        <h1>NetAD</h1>
        <h5>
          Network modeling of bulk and single-cell multi-omics data to identify drivers for
          Alzheimer&apos;s Disease
        </h5>
      </Container>
    </SectionHero>
    <Section className="study__section">
      <Row>
        <Col>
          <h2 id="about">Specific Aim of the Study</h2>
          <p>
            <img src={NetadLogo} alt="netad logo" className="study-logo" />
            The <strong>long-term goal</strong> of this project is to increase our understanding of
            Alzheimer&apos;s disease (AD) by harmonizing and modeling cross-study multi-omics data
            at the bulk and single-cell levels using network-based systems biology tools, thereby
            providing new therapeutic targets towards AD prevention and treatment. AD is the leading
            cause of dementia, affecting more than 5 million people in the US. Despite extensive
            investigation of AD pathogenesis and significant investment in developing therapeutics
            for AD, no cure is currently available. Characterization of AD brains by proteomics,
            transcriptomics, and genomics technologies has played a vital role and laid the
            foundation in dissecting the molecular bases of AD. <strong>However</strong>, few AD
            driver genes or proteins have been reliably identified. Therefore, sophisticated
            computational modeling tools and harmonized data visualization portals{' '}
            <strong>remain urgently needed</strong> to integrate bulk and single-cell multi-omics
            data across studies and platforms for the identification of &quot;hidden&quot; drivers
            for AD.
          </p>

          <p>
            To tackle this challenge, we propose a solution of network modeling of AD (
            <strong>NetAD</strong>). We have assembled a strong team with diverse expertise in
            systems biology (Yu), AD biology and proteomics (Peng, Wang, Li), statistical genomics
            (Wang), visualization (Zhou), and cloud engineering (McLeod), in collaboration with Drs.
            Haroutunian and Bu (AD pathology &amp; pathogenesis). We have pioneered the development
            of network modeling algorithms (NetBID, scMINER) to integrate bulk and single-cell omics
            data and expose &quot;hidden&quot; drivers that may not be genetically altered or
            differentially expressed but drive disease phenotypes via post-translational
            modifications (PTMs) or others. We led AD proteomics studies through the development of
            our versatile JUMP software suite. We built the <strong>St. Jude Cloud</strong> platform
            for omics data sharing, analysis, and visualization. Capitalizing on our unique
            expertise and resources, we propose to implement NetAD with the following Specific Aims
            (<strong>Fig. 1</strong>).
          </p>
          <img src={Figure1} alt="process of project with aims" />
          <figcaption className="text-center mb-5">
            <strong>Figure 1.</strong> Overview of the NetAD project
          </figcaption>
        </Col>
      </Row>
      <Row className="mt-3">
        <Col md={3}>
          <img src={NetadAim1} alt="decorative tag for aim" />
        </Col>
        <Col md={9}>
          <h6>
            <strong>AIM 1</strong>
          </h6>
          <h4>
            Develop sensitive and robust preprocessing, batch correction, and cloud-based
            visualization tools to harmonize cross-study transcriptomics, proteomics, and
            PTM-proteomics data of AD.
          </h4>
          <p>
            To address the challenge of harmonizing proteomics data, we will expand on our JUMP
            software to develop the <em>JUMPp-lib</em> algorithm for ultra-fast, sensitive
            identification of peptides using spectral library search optimized for isobaric labeling
            proteomics and the <em>JUMPp-batch</em> algorithm for imputing missing values and
            normalizing batch effects. We will then use the JUMP suite to quantify
            proteomes/PTM-proteomes (JUMPp) and genotypes (JUMPg) from 1,000+ TMT-based proteomics
            profiles. We will use our well-established in-house pipeline to reprocess 1,000+ RNA-seq
            profiles from multiple AD studies. Using our St. Jude Cloud platform, we will build a
            NetAD portal to centralize and <strong>freely</strong> share the harmonized data,
            including the gene and isoform expression of both RNA and protein levels, and PTM sites,
            via <strong>St. Jude Cloud Community</strong>, <strong>AD Knowledge Portal</strong>, and
            others.
          </p>
        </Col>
      </Row>
      <Row className="mt-3">
        <Col md={3}>
          <img src={NetadAim2} alt="decorative tag for aim" />
        </Col>
        <Col md={9}>
          <h6>
            <strong>AIM 2</strong>
          </h6>
          <h4>
            Develop a network-based framework to integrate bulk transcriptomics, proteomics,
            PTM-proteomics, and genomics data to identify AD drivers.
          </h4>
          <p>
            Capitalizing the success of our NetBID algorithm, we will develop a new version
            (NetBID3) to infer AD hidden drivers by reverse-engineering a reference AD interactome
            from samples with matched transcriptome-proteome profiles and then using this AD map to
            infer peptide/PTM-peptide activity across all 1,000+ brain cases, many of which had only
            RNA-seq or proteomics data. We will extend our expression quantitative trait loci (eQTL)
            analysis to proteome/PTM-proteome (pQTL) and NetBID3-inferred protein activity (aQTL) to
            identify the underlying AD genetic drivers. Finally, we will develop tools in our NetAD
            portal to interrogate and visualize the interactome, driver activity, and pQTL/aQTL
            results.
          </p>
        </Col>
      </Row>
      <Row className="mt-3">
        <Col md={3}>
          <img src={NetadAim3} alt="decorative tag for aim" />
        </Col>
        <Col md={9}>
          <h6>
            <strong>AIM 3</strong>
          </h6>
          <h4>
            Develop a single-cell network modeling algorithm to integrate single-cell and spatial
            omics datasets in humans and mice to identify cell-type-specific AD drivers.
          </h4>
          <p>
            Given the continuously increasing single-cell profiles of AD, we will develop a scMINER2
            algorithm to integrate scRNA-seq data from human and mouse AD brains and model
            cell-type-specific networks and hidden drivers of AD. We will use scMINER2 to
            reconstruct ligand-receptor-driven cell-cell communication maps from single-cell data.
            We will also integrate available spatial transcriptomics data of AD brains to identify
            spatial patterns of NetBID3/scMINER2-identified AD hidden drivers and mapped cell types.
            We will develop visualizations for single-cell data in the NetAD portal.
          </p>
        </Col>
      </Row>
      <Row>
        <Col>
          <p>
            This NetAD project will provide an innovative network modeling solution with new tools
            and a cloud-based easy-to-access portal to explore and visualize harmonized multi-omics
            data, networks, and drivers of AD. It will enable the generation of{' '}
            <strong>new hypotheses</strong> for &quot;hidden&quot; drivers of AD that may lead to
            novel AD therapies.
          </p>
          <img src={Figure2} alt="structure of NetAD project" />
          <figcaption className="text-center mb-5">
            <strong>Figure 2.</strong> The overall design and architecture of NetAD portal.
          </figcaption>
        </Col>
      </Row>
      <Row>
        <Col>
          <h3>PIs:</h3>
          <ul>
            <li>Jiyang Yu, PhD, Associate Member, Department of Computational Biology</li>
            <li>
              Junmin Peng, PhD, Member, Departments of Structural Biology and Developmental
              Neurobiology, Director of the Center for Proteomics and Metabolomics
            </li>
            <li>
              Xusheng Wang, PhD, Assistant Member, Department of Biology, University of North Dakota
            </li>
          </ul>
        </Col>
      </Row>
      <Row>
        <Col>
          <h3>Co-Investigators/collaborators:</h3>
          <ul>
            <li>
              Xin Zhou, PhD, Assistant Member, Director of Data Visualization, Department of
              Computational Biology
            </li>
            <li>
              Yuxin Li, PhD, Principal Bioinformatics Research Scientist, Center for Proteomics and
              Metabolomics
            </li>
            <li>
              Clay McLeod, Director of Product Development and Engineering, Department of
              Computational Biology
            </li>
            <li>
              Vahram Haroutunian, PhD, Professor, Department of Psychiatry and Neuroscience;
              Director, The Mount Sinai NIH Brain and Tissue Repository (NBTR), The Mount Sinai
              School of Medicine
            </li>
            <li>
              Guojun Bu, PhD, Mary Lowell Leary Professor of Medicine; Chair, Department of
              Neuroscience; Associate Director, Alzheimer&quot;s Disease Research Center, Mayo
              Clinic Jacksonville
            </li>
          </ul>
        </Col>
      </Row>
    </Section>
    <Section gray>
      <Row>
        <Col>
          <h2 id="apps">Apps</h2>
          <p>
            Data from the NetAD study will be included in the following St. Jude Cloud applications.
          </p>
        </Col>
      </Row>
      <Row className="study__apps">
        <CardAppPlatform
          lg={6}
          description="Next-generation sequencing data from NetAD will be available to analyze in the cloud."
          buttonText="Coming Soon"
          staticCard
        />
        <CardAppVizComm
          lg={6}
          description="Protein and pathway searches, lightweight data analysis for gene, protein, and phosphopeptide abundance accessible in static and dynamic visualizations."
          buttonText="View Visualizations"
          url="https://viz.stjude.cloud/community/netad~7"
        />
      </Row>
    </Section>
    <Section className="study__section--citation">
      <Row>
        <Col>
          <h2 id="citation">Cite the Program</h2>
        </Col>
      </Row>
      <Row>
        <Col>
          <p>
            Please visit{' '}
            <a href="https://university.stjude.cloud/docs/citing-stjude-cloud/">
              our dedicated documentation page on how to cite St. Jude Cloud
            </a>{' '}
            when publishing manuscripts utilizing data or information from this resource.
          </p>
          <p>
            Questions or Feedback? Contact us at{' '}
            <a href="mailto:support@stjude.cloud">support@stjude.cloud</a>.
          </p>
        </Col>
      </Row>
    </Section>
  </Layout>
);

export default NetadStudyPage;
